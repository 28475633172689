import myResume from "./Asher Hounsell Resume.pdf"
import './App.css';
import React, { useState } from 'react';
import { Document, Page , pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/entry.webpack';
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



function App() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <div className="App">  
      <h1>Hi! I am Asher, and I am building this website with React!</h1>
      <h1>Check back soon...</h1>

      <a href={myResume}>Here's my resume in the meantime</a>
    
      <Document className="pdf-document" file={myResume}> 
      <Page pageNumber={1} renderTextLayer={false} style="display:flex;justify-content:center;align-items:center;" /> {}

      </Document>
      <p></p>
      
    </div>
  );
}

export default App;
